/* global ajax_object */
let category = '';
function postsAjax(showPosts, catId, paged, amount, catAmount, postType) {
  const data = {
    action: 'post_ajax_load',
    nonce: ajax_object.nonce,
    post_type: postType,
    show_posts: showPosts,
    cat_id: catId,
    cat_amount: catAmount,
    paged: paged,
    amount: amount,
  };

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      console.log(response);
      let container =
        response.post_type === 'post'
          ? '.blog__posts-container__inner'
          : '.book-club-archive__books__inner';
      if ($(container).length) {
        $(container).html(response.html);
        if (
          response.amount < response.show_posts &&
          !response.paged &&
          response.cat_amount < response.show_posts
        ) {
          $('.blog__posts-container__pagination').hide();
        } else {
          $('.blog__posts-container__pagination').show();
        }
      }
      // $('html, body').animate(
      //   {
      //     scrollTop: $('.blog__posts-container').offset().top - 50,
      //   },
      //   1000
      // );
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
$(document).on('ready', function () {
  if (
    $('.blog__posts-container__categories').length ||
    $('.book-club-archive__books__categories').length
  ) {
    let cat, postType;
    if ($('.blog__posts-container__categories').length) {
      cat = $('.blog__posts-container__categories').find('.category-name');
      postType = 'post';
    } else {
      cat = $('.book-club-archive__books__categories').find('.category-name');
      postType = 'book-club';
    }
    cat.on('click', function () {
      let catId = $(this).data('cat'),
        show = $(this).data('show'),
        amount = $(this).data('amount'),
        catAmount = $(this).data('cat-count');
      category = catId;
      postsAjax(show, catId, null, amount, catAmount, postType);
    });
  }

  if ($('.blog__posts-container__pagination').length) {
    let postType = $('.blog__posts-container__pagination').data('post-type');
    let pagItmes = $('.blog__posts-container__pagination').find(
      '.pagination-item'
    );
    pagItmes.on('click', function () {
      pagItmes.removeClass('is-active');
      $(this).addClass('is-active');
      let paged = $(this).data('paged'),
        amount = $(this).data('amount'),
        showPosts = $(this).data('show');
      postsAjax(showPosts, category, paged, amount, '', postType);
    });
  }
});
