// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
import './_slider'; // eslint-disable-line
import './_ajax'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
// import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}
// const textBlocksOverlaping = function (arr) {
//   let length = arr.length;
//   arr.each(function (idx, el) {
//     if (idx !== 0) {
//       $(el).css('margin-top', '-2rem');
//     }
//     $(el).css('z-index', length--);
//     if (idx === arr.length - 1) {
//       $(el).css('z-index', arr.length);
//     }
//   });
// };

const accordion = function (el) {
  el.each(function (idx, el) {
    $(el)
      .find('.accordion-title-wrapper')
      .on('click', function () {
        let $this = $(this);
        setTimeout(function () {
          $this.find('.accordion-title__icon').toggleClass('is-active');
          $this.siblings().slideToggle('slow');
        }, 100);
      });
  });
};

const tabs = function (el) {
  let title = $(el).find('.tabs-title'),
    text = $(el).find('.tabs-text');
  $(title).on('click', function () {
    let dataTitle = $(this).data('title'),
      activeText = $(el).find(`.tabs-text[data-text='${dataTitle}']`);
    title.removeClass('is-active');
    $(this).addClass('is-active');
    $(text).slideUp();
    activeText.slideDown('slow');
  });
};
function initCustomSelect() {
  $('select').each(function () {
    var $this = $(this),
      selectClasses = $this.attr('class'),
      numberOfOptions = $(this).children('option').length;
    if ($this.parent('.select').length) {
      return;
    }

    $this
      .addClass('hide')
      .wrap('<div class="select"></div>')
      .after('<div class="select-styled"></div>');

    let $styledSelect = $this.next('div.select-styled');
    $styledSelect
      .text($this.children('option:selected').text())
      .addClass(selectClasses);

    let $list = $('<ul />', {
      class: 'select-options',
    }).insertAfter($styledSelect);

    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val(),
      }).appendTo($list);
    }

    $styledSelect.on('click', function (e) {
      e.stopPropagation();
      $('div.select-styled.active')
        .not(this)
        .each(function () {
          $(this).removeClass('active').next('ul.select-options').hide();
        });
      $(this).toggleClass('active').next('ul.select-options').slideToggle();
    });

    $list.children('li').on('click', function (e) {
      e.stopPropagation();
      $styledSelect
        .text($(this).text())
        .removeClass('active')
        .next('.select-options')
        .hide();
      $this.val($(this).attr('rel')).trigger('change');
    });

    $(document).on('click', function () {
      if ($styledSelect.hasClass('active')) {
        $styledSelect.removeClass('active');
        $list.hide();
      }
    });
  });
}
jQuery(document).on('gform_post_render', function () {
  initCustomSelect();
});
/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Add coma after each category
   */
  // if ($('.categories-container').length) {
  //   let cats = $('.categories-container').find('.cat-item');
  //   cats.each(function (idx, el) {
  //     if (idx < cats.length - 1) {
  //       $(el).append(',');
  //     }
  //   });
  // }
  if ($('.is-triangle').length) {
    $('.is-triangle').next('section').addClass('previos-triangle');
  }
  if ($('.mobile-phone-sticky').length) {
    let $this = $('.mobile-phone-sticky'),
      $siblingMenu = $this.siblings('div');
    $siblingMenu.append($this);
  }
  if ($('.review-page').length) {
    let form = $('.review-page__popup-form'),
      social = $('.review-page__popup-socials'),
      main = $('.review-page__main'),
      stars = main.find('.stars-wrapper'),
      close = $('.close-button');
    close.on('click', function () {
      main.removeClass('is-hiden');
      $(this).closest('section').hide();
    });
    stars.on('click', function () {
      stars.removeClass('is-active');
      $(this).addClass('is-active').prevAll().addClass('is-active');
      if ($(this).data('number') > 3) {
        social.css('display', 'flex');
      } else {
        form.css('display', 'flex');
      }
    });
    stars.mouseover(function () {
      $(this).addClass('is-active').prevAll().addClass('is-active');
      $(this).nextAll().removeClass('is-active');
    });
  }

  if ($('.btn-radius').length) {
    $('.btn-radius.btn-radius--red').mouseenter(function () {
      if ($(this).parent().css('text-align') === 'center') {
        $(this).css('transform', 'translateX(1.5rem)');
      }
    });
    $('.btn-radius.btn-radius--red').mouseleave(function () {
      if ($(this).parent().css('text-align') === 'center') {
        $(this).css('transform', 'translateX(0)');
      }
    });
  }
  initCustomSelect();
  /**
   * Anchor links
   */
  $('.anchor-link').on('click', function (e) {
    e.preventDefault();
    let anchor = $(this).attr('href');
    if ($(`${anchor}`)[0]) {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $(anchor).offset().top - 60,
          },
          1500
        );
    }
  });
  /**
   * Burger menu button
   */
  $('.menu-icon').on('click', function () {
    $(this).toggleClass('is-active');
    $(this).siblings('.mobile-phone').toggle();
    $('.top-bar').slideToggle('300');
  });
  // Heading line
  $('.heading-left-line').each(function () {
    $(this).css('overflow', 'hidden');
    const lastWord = $(this).html().split(' ').pop(),
      lastIndex = $(this).html().lastIndexOf(lastWord);
    $(this).html().substring(0, lastIndex);
    $(this).append(`<span class='text-line'></span>`);
  });
  // End Heading line
  // Arrow scroll up to anchor
  $('.arrow-up').click(function () {
    $('html, body').stop().animate(
      {
        scrollTop: 0,
      },
      2000
    );
  });
  // End Arrow scroll up to anchor
  // Custom functions -----------
  // textBlocksOverlaping($('.text-block-colored'));
  accordion($('.accordion__content'));
  tabs($('.tabs__wrapper'));
  // End Custom functions -------
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  // $(window)
  //   .on('toggled.zf.responsiveToggle', function () {
  //     $('.menu-icon').toggleClass('is-active');
  //   })
  //   .on('changed.zf.mediaquery', function () {
  //     $('.menu-icon').removeClass('is-active');
  //   });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  // $('.header__phone-container').css(
  //   'margin-top',
  //   $('#header-top-banner').innerHeight()
  // );

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
// let stickyHeader = $('.menu-grid-container').clone();
// let eListener = false;
// if (eListener) {
//   $('.header-menu-wrapper').append(stickyHeader);
//   stickyHeader.addClass('sticky-header');
// } else {
//   stickyHeader.remove().removeClass('sticky-header');
// }
$(window).on('scroll', function () {
  // jQuery code goes here
  if ($(window).scrollTop() > 300) {
    // $('.arrow-up').show();
    $('.menu-grid-container-sticky').addClass('sticky-header');
  } else {
    // $('.arrow-up').hide();
    $('.menu-grid-container-sticky').removeClass('sticky-header');
  }
});
