import Swiper, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  EffectCoverflow,
  Thumbs,
  Lazy,
} from 'swiper';

Swiper.use([
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
  Lazy,
]);

$(document).on('ready', function () {
  // Project slider
  const homeSliderContent = new Swiper('.home-page__slider-content', {
    speed: 0,
    autoplay: {
      delay: 8000,
    },
    parallax: false,
    spaceBetween: 0,
    slidesPerView: 1,
    freeMode: true,
    watchSlidesProgress: true,
  });
  homeSliderContent.init();
  if (window.innerWidth < 1024) {
    homeSliderContent.autoplay.stop();
  }
  const homeSlider = new Swiper('.home-page__slider', {
    speed: 2000,
    autoplay: {
      delay: 7000,
    },
    spaceBetween: 0,
    loop: false,
    effect: 'slide',
    slidesPerView: 1,
    freeMode: true,
    grabCursor: true,
    allowTouchMove: true,
    thumbs: {
      swiper: homeSliderContent,
    },
    navigation: {
      nextEl: '.home-button-next',
      prevEl: '.home-button-prev',
    },
  });
  homeSlider.init();
  if (window.innerWidth < 1024) {
    homeSlider.autoplay.stop();
  }
  const textColumn = new Swiper('.text-columns__inner', {
    parallax: false,
    slidesPerView: 1,
    grabCursor: false,
    freeMode: true,
    enabled: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1024: {
        spaceBetween: '48px',
        slidesPerView: 3,
        enabled: false,
      },
    },
  });
  textColumn.init();

  const relatedSlider = new Swiper('.swiper-related-articles', {
    slidesPerView: 1.1,
    grabCursor: true,
    spaceBetween: 15,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  });
  relatedSlider.init();
});
